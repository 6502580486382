/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react'
import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {ErrorsPage} from './modules/errors/ErrorsPage'
import {App} from './App'
import {AuthLayout} from './modules/auth/AuthLayout'
import {MasterLayout} from '../_metronic/layout/MasterLayout'
import {routes} from '../core/routes'

const AppRoutes: FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<App />}>
                    <Route path='error/*' element={<ErrorsPage />} />

                    <Route element={<MasterLayout />}>
                        <Route
                            path={routes.homeDashboard.href()}
                            element={<routes.homeDashboard.Component />}
                        />
                        <Route
                            path={routes.accountProfile.href()}
                            element={<routes.accountProfile.Component />}
                        />
                        <Route
                            path={routes.logActivityLogManagement.href()}
                            element={<routes.logActivityLogManagement.Component />}
                        />
                        <Route
                            path={routes.logActivityLogManagementById.href(':activityId')}
                            element={<routes.logActivityLogManagementById.Component />}
                        />

                        <Route
                            path={routes.ticketManagement.href()}
                            element={<routes.ticketManagement.Component />}
                        />
                        <Route
                            path={routes.ticketById.href(':ticketId')}
                            element={<routes.ticketById.Component />}
                        />
                        <Route
                            path={routes.ticketAdminById.href(':ticketId')}
                            element={<routes.ticketAdminById.Component />}
                        />
                        <Route
                            path={routes.viewStaffById.href(':staffId')}
                            element={<routes.viewStaffById.Component />}
                        />
                        <Route
                            path={routes.purchaseOrderManagement.href()}
                            element={<routes.purchaseOrderManagement.Component />}
                        />
                        <Route
                            path={routes.purchaseOrderAdminManagement.href()}
                            element={<routes.purchaseOrderAdminManagement.Component />}
                        />
                        <Route
                            path={routes.purchaseOrderById.href(':purchaseOrderId')}
                            element={<routes.purchaseOrderById.Component />}
                        />

                        <Route
                            path={routes.saleOrderManagement.href()}
                            element={<routes.saleOrderManagement.Component />}
                        />
                        <Route
                            path={routes.saleOrderById.href(':saleOrderId')}
                            element={<routes.saleOrderById.Component />}
                        />
                        <Route
                            path={routes.chatHome.href()}
                            element={<routes.chatHome.Component />}
                        />
                        <Route
                            path={routes.staffManagement.href()}
                            element={<routes.staffManagement.Component />}
                        />
                        <Route
                            path={routes.ticketAdminManagement.href()}
                            element={<routes.ticketAdminManagement.Component />}
                        />
                        <Route
                            path={routes.claimEmailManagement.href()}
                            element={<routes.claimEmailManagement.Component />}
                        />

                        <Route path='*' element={<Navigate to='/error/404' />} />
                    </Route>

                    <Route element={<AuthLayout />}>
                        <Route
                            path={routes.authLogin.href()}
                            element={<routes.authLogin.Component />}
                        />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export {AppRoutes}
