import {createRoot} from 'react-dom/client'
// Axios
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'

import {Provider} from 'react-redux'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/AppRoutes'
import './styles/index.css'
import 'antd/dist/antd.min.css'
import {store} from './core/stores'
import {AuthUserProvider} from './core/contexts'

Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
    createRoot(container).render(
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <MetronicI18nProvider>
                    <AuthUserProvider>
                        <AppRoutes />
                    </AuthUserProvider>
                </MetronicI18nProvider>
                <ToastContainer />
            </QueryClientProvider>
        </Provider>
    )
}
