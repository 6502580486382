import React from 'react'
import {ActivityLogManagementByDate} from '../pages/log/ActivityLogManagementByDate'
import {TicketAdminById} from '../pages/ticket/TicketAdminById'

import {UnAuthRouterProtection, AuthRouterProtection} from './components/routerProtection'
import {ModalProvider, TableUtilProvider} from './contexts'

const HomeDashboardPage = React.lazy(() =>
    import('../pages/home/HomeDashboard').then(({HomeDashboard}) => ({default: HomeDashboard}))
)

const AccountProfilePage = React.lazy(() =>
    import('../pages/account/AccountProfile').then(({AccountProfile}) => ({
        default: AccountProfile,
    }))
)

const ActivityLogManagementPage = React.lazy(() =>
    import('../pages/log/ActivityLogManagement').then(({ActivityLogManagement}) => ({
        default: ActivityLogManagement,
    }))
)

const PurchaseOrderManagementPage = React.lazy(() =>
    import('../pages/purchaseOrder/PurchaseOrderManagement').then(
        ({PurchaseOrderManagement: PurchaseManagement}) => ({
            default: PurchaseManagement,
        })
    )
)
const PurchaseOrderAdminManagementPage = React.lazy(() =>
    import('../pages/purchaseOrder/PurchaseOrderAdminManagement').then(
        ({PurchaseOrderAdminManagement}) => ({
            default: PurchaseOrderAdminManagement,
        })
    )
)

const PurchaseOrderByIdPage = React.lazy(() =>
    import('../pages/purchaseOrder/PurchaseOrderById').then(({PurchaseOrderById}) => ({
        default: PurchaseOrderById,
    }))
)

const SalesOrderManagementPage = React.lazy(() =>
    import('../pages/saleOrder/SaleOrderManagement').then(({SaleOrderManagement}) => ({
        default: SaleOrderManagement,
    }))
)

const SalesOrderByIdPage = React.lazy(() =>
    import('../pages/saleOrder/SaleOrderById').then(({SaleOrderById}) => ({
        default: SaleOrderById,
    }))
)

const TicketManagementPage = React.lazy(() =>
    import('../pages/ticket/TicketManagement').then(({TicketManagement}) => ({
        default: TicketManagement,
    }))
)
const TicketAdminManagementPage = React.lazy(() =>
    import('../pages/ticket/TicketAdminManagement').then(({TicketAdminManagement}) => ({
        default: TicketAdminManagement,
    }))
)

const TicketByIdPage = React.lazy(() =>
    import('../pages/ticket/TicketById').then(({TicketById}) => ({
        default: TicketById,
    }))
)

const ChatHome = React.lazy(() =>
    import('../pages/chat/ChatHome').then(({ChatHome}) => ({
        default: ChatHome,
    }))
)

const LoginPage = React.lazy(() =>
    import('../pages/auth/LoginPage').then((module) => ({
        default: module.Login,
    }))
)

const StaffManagementPage = React.lazy(() =>
    import('../pages/staff/StaffManagement').then(({StaffManagement}) => ({
        default: StaffManagement,
    }))
)

const ViewStaffByIdPage = React.lazy(() =>
    import('../pages/staff/ViewStaffById').then(({ViewStaffById}) => ({
        default: ViewStaffById,
    }))
)

const ClaimCVManagementPage = React.lazy(() =>
    import('../pages/claimEmail/ClaimEmailManagement').then(({ClaimEmailManagement}) => ({
        default: ClaimEmailManagement,
    }))
)

export interface IRoute {
    href: (data?: any) => string
    Component: () => React.ReactElement
}

export const routes: Record<string, IRoute> = {
    authLogin: {
        href: () => '/auth',
        Component: () => (
            <UnAuthRouterProtection>
                <LoginPage />
            </UnAuthRouterProtection>
        ),
    },
    accountProfile: {
        href: () => '/account/profile',
        Component: () => (
            <AuthRouterProtection>
                <AccountProfilePage />
            </AuthRouterProtection>
        ),
    },
    homeDashboard: {
        href: () => '/',
        Component: () => (
            <AuthRouterProtection>
                <HomeDashboardPage />
            </AuthRouterProtection>
        ),
    },
    logActivityLogManagement: {
        href: () => '/log/activity',
        Component: () => (
            <AuthRouterProtection>
                <TableUtilProvider>
                    <ActivityLogManagementPage />
                </TableUtilProvider>
            </AuthRouterProtection>
        ),
    },
    logActivityLogManagementById: {
        href: (id: string) => `/log/activity/${id}`,
        Component: () => (
            <AuthRouterProtection>
                <TableUtilProvider>
                    <ActivityLogManagementByDate />
                </TableUtilProvider>
            </AuthRouterProtection>
        ),
    },

    ticketManagement: {
        href: () => '/ticket',
        Component: () => (
            <AuthRouterProtection>
                <ModalProvider>
                    <TableUtilProvider>
                        <TicketManagementPage />
                    </TableUtilProvider>
                </ModalProvider>
            </AuthRouterProtection>
        ),
    },
    ticketAdminManagement: {
        href: () => '/admin/ticket',
        Component: () => (
            <AuthRouterProtection>
                <ModalProvider>
                    <TableUtilProvider>
                        <TicketAdminManagementPage />
                    </TableUtilProvider>
                </ModalProvider>
            </AuthRouterProtection>
        ),
    },
    ticketById: {
        href: (id: string) => `/ticket/${id}`,
        Component: () => (
            <AuthRouterProtection>
                <ModalProvider>
                    <TicketByIdPage />
                </ModalProvider>
            </AuthRouterProtection>
        ),
    },
    ticketAdminById: {
        href: (id: string) => `/admin/ticket/${id}`,
        Component: () => (
            <AuthRouterProtection>
                <ModalProvider>
                    <TicketAdminById />
                </ModalProvider>
            </AuthRouterProtection>
        ),
    },

    purchaseOrderManagement: {
        href: () => '/purchase-order',
        Component: () => (
            <AuthRouterProtection>
                <TableUtilProvider>
                    <ModalProvider>
                        <PurchaseOrderManagementPage />
                    </ModalProvider>
                </TableUtilProvider>
            </AuthRouterProtection>
        ),
    },
    purchaseOrderAdminManagement: {
        href: () => '/admin/purchase-order',
        Component: () => (
            <AuthRouterProtection>
                <TableUtilProvider>
                    <ModalProvider>
                        <PurchaseOrderAdminManagementPage />
                    </ModalProvider>
                </TableUtilProvider>
            </AuthRouterProtection>
        ),
    },

    purchaseOrderById: {
        href: (id: string) => `/purchase-order/${id}`,
        Component: () => (
            <AuthRouterProtection>
                <ModalProvider>
                    <PurchaseOrderByIdPage />
                </ModalProvider>
            </AuthRouterProtection>
        ),
    },

    saleOrderManagement: {
        href: () => '/sale-order',
        Component: () => (
            <AuthRouterProtection>
                <TableUtilProvider>
                    <ModalProvider>
                        <SalesOrderManagementPage />
                    </ModalProvider>
                </TableUtilProvider>
            </AuthRouterProtection>
        ),
    },
    saleOrderById: {
        href: (id: string) => `/sale-order/${id}`,
        Component: () => (
            <AuthRouterProtection>
                <SalesOrderByIdPage />
            </AuthRouterProtection>
        ),
    },
    chatHome: {
        href: () => '/chat',
        Component: () => (
            <AuthRouterProtection>
                <ChatHome />
            </AuthRouterProtection>
        ),
    },
    staffManagement: {
        href: () => '/staff',
        Component: () => (
            <AuthRouterProtection>
                <TableUtilProvider>
                    <ModalProvider>
                        <StaffManagementPage />
                    </ModalProvider>
                </TableUtilProvider>
            </AuthRouterProtection>
        ),
    },
    viewStaffById: {
        href: (id: string) => `/staff/${id}`,
        Component: () => (
            <AuthRouterProtection>
                <ModalProvider>
                    <ViewStaffByIdPage />
                </ModalProvider>
            </AuthRouterProtection>
        ),
    },
    claimEmailManagement: {
        href: () => '/claim-email',
        Component: () => (
            <AuthRouterProtection>
                <TableUtilProvider>
                    <ModalProvider>
                        <ClaimCVManagementPage />
                    </ModalProvider>
                </TableUtilProvider>
            </AuthRouterProtection>
        ),
    },
}
