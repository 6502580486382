import {PurchaseOrder} from '../models'
import {stringify} from 'query-string'
import {IPagingProps} from '../models'
import {http} from './http'

export interface IV1PurchaseOrderMeFilters extends Pick<Partial<PurchaseOrder>, 'company_name'> {
    price: number
    email: string
    phone: string
}
export interface IV1PurchaseOrderMe extends IPagingProps<IV1PurchaseOrderMeFilters> {}

export interface IV1PurchaseOrderFilters extends Pick<Partial<PurchaseOrder>, 'company_name'> {
    price: number
    email: string
    phone: string
}
export interface IV1PurchaseOrder extends IPagingProps<IV1PurchaseOrderFilters> {}

export const purchaseOrderApi = {
    v1Me: (query: IV1PurchaseOrderMe) => {
        return http.get(
            `/v1/api/purchase-order/me?${stringify({
                ...query,
                ...query.filters,
            })}`
        )
    },
    v1Paging: (query: IV1PurchaseOrder) => {
        return http.get(
            `/v1/api/purchase-order/paging?${stringify({
                ...query,
                ...query.filters,
            })}`
        )
    },

    v1ById: (id: string) => {
        return http.get(`/v1/api/purchase-order/${id}`)
    },

    v1Delete: (id: string) => {
        return http.delete(`/v1/api/purchase-order/${id}`)
    },
    v1Approve: (id: string) => {
        return http.patch(`/v1/api/purchase-order/approve/${id}`)
    },
    v1UpdateInvoice: (id: string, object: any) => {
        return http.patch(`/v1/api/purchase-order/invoice/${id}`, object)
    },
    v1GetInvoice: (id: string) => {
        return http.get(`/v1/api/purchase-order/invoice/${id}`)
    },
    v1GetCountries: () => {
        return http.get(`/v1/api/country`)
    },
    v1GetCites: () => {
        return http.get(`/v1/api/city`)
    },
    v1GetDistrict: (id: string) => {
        return http.get(`/v1/api/district/city/${id}`)
    },
}
