import {FormikProvider, useFormik} from 'formik'
import _get from 'lodash.get'
import * as React from 'react'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {IV1ReportChangeType, reportApi} from '../../../core/apis'
import {FormButton, ModalHeader, ModelWrapper, SelectField} from '../../../core/components'
import {useModal} from '../../../core/contexts'
import {Report, reportTypeOptions} from '../../../core/models'

interface TicketChangeTypeModalProps {}

export const TicketChangeTypeModal: React.FC<TicketChangeTypeModalProps> = () => {
    const {modalValue, handleCloseModal} = useModal<Report>('changeType')

    const mutation = useMutation((input: IV1ReportChangeType) => {
        return reportApi.v1UpdateType(_get(modalValue, 'uuid', ''), input)
    })

    const formik = useFormik<IV1ReportChangeType>({
        initialValues: {
            type: _get(modalValue, 'type_report', ''),
        },

        enableReinitialize: true,
        onSubmit: async (values) => {
            if (values.type === _get(modalValue, 'type_report', '')) {
                toast.error('Please choose another type')
                return
            }
            mutation.mutate(values, {
                onError: (error: any) => {
                    toast.error(error.data.message)
                },
                onSuccess: () => {
                    toast.success('Update ticket status successfully')
                    handleCloseModal()
                },
            })
        },
    })

    return (
        <>
            <ModelWrapper
                title={<ModalHeader title='Update Ticket Status' modalKey='changeType' />}
            >
                <FormikProvider value={formik}>
                    <form
                        id='kt_modal_add_user_form'
                        className='form'
                        onSubmit={formik.handleSubmit}
                    >
                        {/* begin::Scroll */}
                        <div
                            className='d-flex flex-column scroll-y me-n7 pe-7'
                            id='kt_modal_add_user_scroll'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: false, lg: true}'
                            data-kt-scroll-max-height='auto'
                            data-kt-scroll-dependencies='#kt_modal_add_user_header'
                            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                            data-kt-scroll-offset='300px'
                        >
                            {/* begin::Input group */}
                            <SelectField label='New Type' name='type' options={reportTypeOptions} />

                            {/* <TextField name='name' label='Name' />
                            <TextField name='base' label='Base' />
                            <TextField name='release_year' label='Year of release' /> */}

                            {/* end::Input group */}
                            {/* begin::Input group */}
                            {/* end::Input group */}
                        </div>
                        {/* end::Scroll */}

                        {/* begin::Actions */}
                        <div className='text-center pt-15'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-users-modal-action='cancel'
                                onClick={() => handleCloseModal()}
                            >
                                Discard
                            </button>

                            <FormButton label='Submit' isLoading={mutation.isLoading} />
                        </div>
                        {/* end::Actions */}
                    </form>
                </FormikProvider>
            </ModelWrapper>
        </>
    )
}
