import {IOptionItem} from './interface'
import {User} from './user'

export enum ReportType {
    R_TECH = 'R_TECH',
    R_RECEIPT = 'R_RECEIPT',
}
export enum ReportStatus {
    OPEN = 'OPEN',
    IN_PROCESS = 'IN_PROCESS',
    BLOCKED = 'BLOCKED',
    CLOSED = 'CLOSED',
    DONE = 'DONE',
}

export interface Report {
    createdAt: string
    updatedAt: string
    uuid: string
    type_report: ReportType
    status: ReportStatus
    title: string
    description: string
    files: string
    user: User | undefined
}

export const reportTypeOptions: IOptionItem[] = [
    {
        value: ReportType.R_TECH,
        label: 'Report Technical',
        data: ReportType.R_TECH,
    },
    {
        value: ReportType.R_RECEIPT,
        label: 'Report Receipt',
        data: ReportType.R_RECEIPT,
    },
]

export const reportStatusOptions: IOptionItem[] = [
    {
        value: ReportStatus.OPEN,
        label: 'Open',
        data: ReportStatus.OPEN,
    },
    {
        value: ReportStatus.IN_PROCESS,
        label: 'In Process',
        data: ReportStatus.IN_PROCESS,
    },
    {
        value: ReportStatus.BLOCKED,

        label: 'Blocked',
        data: ReportStatus.BLOCKED,
    },
    {
        value: ReportStatus.CLOSED,
        label: 'Closed',
        data: ReportStatus.CLOSED,
    },
    {
        value: ReportStatus.DONE,
        label: 'Done',
        data: ReportStatus.DONE,
    },
]
