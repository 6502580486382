import { stringify } from 'query-string'
import { IPagingProps, SaleOrder } from '../models'
import { http } from './http'

export interface IV1SaleOrderFilters extends Pick<Partial<SaleOrder>, 'type'> {
    email: string
    phone: string
}
export interface IV1PSaleOrderMe extends IPagingProps<IV1SaleOrderFilters> {}

export const saleOrderApi = {
    v1Paging: (query: IV1PSaleOrderMe) => {
        return http.get(
            `/v1/api/sale-order/paging?${stringify({
                ...query,
                ...query.filters,
            })}`
        )
    },
    v1ById: (id: string) => {
        return http.get(`/v1/api/sale-order/${id}`)
    },
}
