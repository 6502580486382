import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {configs} from '../config'
import {apiReducer, ApiState} from './api.reducer'
import {useSelector} from 'react-redux'

export interface RootState {
    api: ApiState
}

const reducers = combineReducers<RootState>({
    api: apiReducer,
})

export const store = configureStore({
    reducer: reducers,
    devTools: configs.MODE !== 'production',
})
export const useStoreApi: () => ApiState = () =>
    useSelector<RootState, ApiState>((state) => state.api)
