import { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useAuthUser } from '../core/contexts'

import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'

const App = () => {
    const {getCurrentUser} = useAuthUser()

    useEffect(() => {
        getCurrentUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <I18nProvider>
                <LayoutProvider>
                    <Outlet />
                    <MasterInit />
                </LayoutProvider>
            </I18nProvider>
        </Suspense>
    )
}

export { App }
