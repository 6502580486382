import * as React from 'react'
import {ColumnInstance, TableInstance} from 'react-table'
import {UsersListLoading} from '../../../app/modules/apps/user-management/users-list/components/loading/UsersListLoading'

interface TableBuilderProps {
    methods: TableInstance<any>
    isLoading: boolean
}

export const TableBuilder: React.FC<TableBuilderProps> = ({methods, isLoading}) => {
    const {getTableProps, getTableBodyProps, rows, prepareRow, headers} = methods
    return (
        <>
            <table
                {...getTableProps}
                className='table table-rounded table-striped border gy-7 gs-7'
            >
                <thead>
                    <tr className='fw-bolder fs-6 text-gray-800'>
                        {headers.map((column: ColumnInstance<any>) => column.render('Header'))}
                    </tr>
                </thead>
                <tbody {...getTableBodyProps}>
                    {!isLoading && (
                        <>
                            {Boolean(!rows.length) && (
                                <tr>
                                    <td>No Data</td>
                                </tr>
                            )}
                            {rows.map((row, i) => {
                                prepareRow(row)

                                return (
                                    <tr key={row.original._id}>
                                        {row.cells.map((cell) => cell.render('Cell'))}
                                    </tr>
                                )
                            })}
                        </>
                    )}
                </tbody>
            </table>
            {isLoading && <UsersListLoading />}
        </>
    )
}
