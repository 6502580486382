import * as React from 'react'

interface DropDownWrapperProps {
    isShow: Boolean
    title: string
    children: React.ReactNode
}

export const DropDownWrapper: React.FC<DropDownWrapperProps> = ({isShow, title, children}) => {
    const ref = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        if (isShow && ref.current) {
            ref.current?.classList.add('show')
        }
        if (!isShow && ref.current) {
            ref.current?.classList.remove('show')
        }
    }, [isShow])

    return (
        <div
            className='menu menu-sub menu-sub-dropdown w-250px w-md-300px '
            data-kt-menu='true'
            data-popper-placement='bottom-end'
            ref={ref}
        >
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>{title}</div>
            </div>
            <div className='separator border-gray-200'></div>
            <div className='px-7 py-5'>{children}</div>
        </div>
    )
}
