import {http} from './http'
import _get from 'lodash.get'
import {stringify} from 'query-string'
import {StaticChart, StaticChartType} from '../models'

export interface V1ChartCommon {
    date: string
    type: StaticChartType
}

export interface ChartLineItem {
    from_date: string
    to_date: string
    value: number
}

export const chartApi = {
    v1Chart: async (input: V1ChartCommon): Promise<StaticChart> => {
        const res = await http.get(
            `/v1/api/chart?${stringify({
                ...input,
            })}`
        )

        return _get(res, 'data.data')
    },
}
