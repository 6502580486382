import {useFormikContext} from 'formik'
import * as React from 'react'
import _clsx from 'clsx'
import {InputSize, InputWrapper} from './InputWrapper'
import {useThemeMode} from '../../../_metronic/partials'
interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string
    label: string
    spaceSize?: InputSize
}

export const TextField: React.FC<TextFieldProps> = ({
    name,
    label,
    spaceSize = InputSize.MEDIUM,
    disabled = false,
    ...rest
}) => {
    const {getFieldProps} = useFormikContext()
    const {mode} = useThemeMode()

    return (
        <InputWrapper label={label} name={name} spaceSize={spaceSize}>
            <input
                {...getFieldProps(name)}
                className={_clsx('form-control', {
                    'bg-white': !disabled,
                    'bg-dark': disabled && mode === 'dark',
                    'bg-light': disabled && mode === 'light',
                })}
                name={name}
                disabled={disabled}
                {...rest}
            />
        </InputWrapper>
    )
}
