import { stringify } from 'query-string'
import { IPagingProps } from '../models'
import { http } from './http'

export interface IV1ClaimEmailFilters {
    search: string
}
export interface IV1ClaimEmail extends IPagingProps<IV1ClaimEmailFilters> {}

export const profileApi = {
    v1ClaimEmail: (query: IV1ClaimEmail) => {
        return http.get(
            `/v1/api/profile/find-all-cv-not-claim?${stringify({
                ...query,
                ...query.filters,
            })}`
        )
    },
    v1SendEmail: (id: string) => {
        return http.post(`/v1/api/profile/send-email`, {
            listEmail: [id],
        })
    },
}
