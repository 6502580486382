import * as React from 'react'
import {useTableUtil} from '../../contexts'
import _clsx from 'clsx'
import Pagination from 'rc-pagination'
interface TablePaginationProps {}

export const TablePagination: React.FC<TablePaginationProps> = () => {
    const {page, limit, handleOnChangePage, totalItems: totalPage} = useTableUtil()

    return (
        <div className='row'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div id='kt_table_users_paginate'>
                    <ul className='pagination'>
                        <Pagination
                            className='pagination'
                            total={totalPage}
                            pageSize={limit}
                            hideOnSinglePage={true}
                            onChange={(newPage) => {
                                handleOnChangePage(newPage)
                            }}
                            itemRender={(item, type) => {
                                if (type === 'next') {
                                    return (
                                        <div className={_clsx('page-item next', {})}>
                                            <button
                                                className={_clsx('page-link page-text', {})}
                                                style={{cursor: 'pointer'}}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    )
                                }
                                if (type === 'prev') {
                                    return (
                                        <div className={_clsx('page-item previous', {})}>
                                            <button
                                                className={_clsx('page-link page-text', {})}
                                                style={{cursor: 'pointer'}}
                                            >
                                                Previous
                                            </button>
                                        </div>
                                    )
                                }

                                if (type === 'page') {
                                    return (
                                        <div
                                            key={item}
                                            className={_clsx('page-item', {
                                                active: item === page,
                                            })}
                                        >
                                            <p
                                                className={_clsx('page-link', {})}
                                                style={{cursor: 'pointer'}}
                                            >
                                                {item}
                                            </p>
                                        </div>
                                    )
                                }

                                return <></>
                            }}
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}
