import {useFormikContext} from 'formik'
import * as React from 'react'
import _clsx from 'clsx'
import {InputSize, InputWrapper} from './InputWrapper'
import {useThemeMode} from '../../../_metronic/partials'
interface SelectFieldProps extends React.InputHTMLAttributes<HTMLSelectElement> {
    name: string
    label: string
    options: Array<{label: string; value: any}>
    spaceSize?: InputSize
    defaultValue?: any
}

export const SelectField: React.FC<SelectFieldProps> = ({
    name,
    label,
    options,
    defaultValue,
    spaceSize = InputSize.MEDIUM,
    disabled = false,
    ...rest
}) => {
    const {getFieldProps} = useFormikContext()
    const {mode} = useThemeMode()

    return (
        <InputWrapper label={label} name={name} spaceSize={spaceSize}>
            <select
                id={name}
                className={_clsx('form-select', {
                    'bg-white': !disabled,
                    'bg-dark': disabled && mode === 'dark',
                    'bg-light': disabled && mode === 'light',
                })}
                {...rest}
                {...getFieldProps(name)}
            >
                {options.map((item) => (
                    <option key={item.value} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>
        </InputWrapper>
    )
}
