

import { Package } from "./package"
import { User } from "./user"

export interface PurchaseOrder {
    createdAt: string
    updatedAt: string
    uuid: string
    po_no: string
    company_no: string
    company_name: string
    approved_date: string
    is_approved: boolean
    is_deleted: boolean
    user:  User | undefined
    package: Package | undefined
    price: number
    value: number
}

export enum EPaymentMethod {
    TRANSFER = 1,
    VNPAY = 2,
    POINT_REQUEST_APPROVED = 3,
    REFERRAL = 4,
    POINT_REFUND_REPORT_APPROVED = 5,
    SURVEY = 6
  }