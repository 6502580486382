import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { WithChildren } from '../../_metronic/helpers'
import { CONSTANTS } from '../constants'
import { SortOrder } from '../models'

export interface ITableUtilContext {
    limit: number
    page: number
    totalItems: number
    sortField: string
    sortOrder: SortOrder
    handleOnChangePage: (page: number) => void
    setTotalItems: React.Dispatch<React.SetStateAction<number>>
    handleOnChangeOrderFiled: (field: string) => void
    setFilters: React.Dispatch<React.SetStateAction<{}>>
    filters: {}
}

const TableUtilContext = React.createContext<ITableUtilContext>({
    limit: 10,
    page: 1,
    totalItems: 0,
    handleOnChangePage: () => {},
    setTotalItems: () => {},
    handleOnChangeOrderFiled: () => {},
    sortField: '',
    sortOrder: SortOrder.ASC,
    setFilters: () => {},
    filters: {},
})

export const TableUtilProvider: React.FC<WithChildren> = ({children}) => {
    const [limit, setLimit] = React.useState<number>(10)
    const [page, setPage] = React.useState<number>(1)
    const [totalItems, setTotalItems] = React.useState<number>(0)
    const [sortField, setSortField] = React.useState<string>(CONSTANTS.DEFAULT_SORT_FIELD)
    const [sortOrder, setSortOrder] = React.useState<SortOrder>(SortOrder.ASC)
    const [searchParams, setSearchParams] = useSearchParams({})
    const [filters, setFilters] = React.useState({})

    React.useEffect(() => {
        const prams = {
            limit: Number(searchParams.get('limit')) || 10,
            page: Number(searchParams.get('page')) || 1,
        }

        setLimit(prams.limit)
        setPage(prams.page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOnChangeOrderFiled = (field: string) => {
        if (sortField === field) {
            if (sortOrder === SortOrder.ASC) {
                setSortOrder(SortOrder.DESC)
            } else {
                setSortField(CONSTANTS.DEFAULT_SORT_FIELD)
                setSortOrder(SortOrder.ASC)
            }
        } else {
            setSortField(field)
            setSortOrder(SortOrder.ASC)
        }
    }

    const handleOnChangePage = (page: number) => {
        setSearchParams({page: String(page)})
        setPage(page)
    }

    return (
        <TableUtilContext.Provider
            value={{
                limit,
                page,
                filters,
                setFilters,
                handleOnChangePage,
                totalItems,
                setTotalItems,
                handleOnChangeOrderFiled,
                sortField,
                sortOrder,
            }}
        >
            {children}
        </TableUtilContext.Provider>
    )
}

export const useTableUtil = () => {
    const context = React.useContext(TableUtilContext)

    return {...context}
}
