import * as React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useClickOutside} from '../../hooks'
import {DropDownMenu} from '../menu'

interface ActionCellProps {
    label: string
    listActions: Array<{label: string; onClick: () => void}>
}

export const ActionCell: React.FC<ActionCellProps> = ({label, listActions}) => {
    const [isShow, setIsShow] = React.useState(false)
    const refEle = useClickOutside<HTMLTableDataCellElement>(() => {
        if (isShow) {
            console.log('123')
            setIsShow(false)
        }
    })

    return (
        <td ref={refEle}>
            <button
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                onClick={() => setIsShow((pre) => !pre)}
            >
                {label}
                <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
            </button>
            {/* begin::Menu */}

            <DropDownMenu isShow={isShow}>
                {/* begin::Menu item */}
                {listActions.map((action, index) => (
                    <div className='menu-item px-3' key={action.label} onClick={action.onClick}>
                        <span className='menu-link px-3'>{action.label}</span>
                    </div>
                ))}
                {/* end::Menu item */}

                {/* begin::Menu item */}

                {/* end::Menu item */}
            </DropDownMenu>

            {/* end::Menu */}
        </td>
    )
}
