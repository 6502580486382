import _get from 'lodash.get'
import React from 'react'
import { useQuery } from 'react-query'
import { Column, useTable } from 'react-table'
import { BodyCell, TableBuilder, TableHeader, ToolBar } from '../../core/components'
import { useModal } from '../../core/contexts'
import { formatDateTime } from '../../core/utils'

import { useParams } from 'react-router-dom'
import { activityApi } from '../../core/apis/activity.api'
import { Activity } from '../../core/models'

const activityHistoryColumns: ReadonlyArray<Column<Activity>> = [
    {
        Header: (props) => <TableHeader label='Message' sortKey={props.column.id} />,
        id: 'message',
        Cell: ({...props}) => {
            const index: number = _get(props, 'row.index', 0)
            const data = _get(props, `data[${index}].message`, [])

            return <BodyCell label={`${data}`} />
        },
    },
    {
        Header: (props) => <TableHeader label='Created At' sortKey={props.column.id} />,
        id: 'createdAt',
        Cell: ({...props}) => {
            const index: number = _get(props, 'row.index', 0)
            const data = _get(props, `data[${index}].createdAt`, [])
            const format = formatDateTime(new Date(data))
            return <BodyCell label={`${format}`} />
        },
    },
]

interface ActivityLogManagementByDateProps {}

export const ActivityLogManagementByDate: React.FC<ActivityLogManagementByDateProps> = () => {
    const params = useParams<{activityId: string}>()

    const updateModalMethod = useModal('edit')

    const query = useQuery<Activity>(
        [params.activityId, updateModalMethod.getIsOpen()],
        async () => {
            const {data} = await activityApi.v1GetById(params.activityId || '')

            return data
        },
        {}
    )

    const tableMethods = useTable<any>({
        columns: activityHistoryColumns,
        data: query.data?.logActivity || [],
    })

    return (
        <>
            <ToolBar title='Activity Log Management'>
                <div className='m-0 '>
                    <button
                        className='btn btn-sm fw-bold btn-primary'
                        onClick={() => {
                            window.history.back()
                        }}
                    >
                        Go Back
                    </button>
                </div>
            </ToolBar>
            <div className='card mt-4 p-4 app-container fade-in'>
                <TableBuilder methods={tableMethods} isLoading={false} />
            </div>
        </>
    )
}
