import * as React from 'react'

export const useClickOutside = <T extends HTMLElement>(handler: () => void) => {
    const refEle = React.useRef<T>(null)

    const handleClickOutside = (event) => {
        if (refEle.current && !refEle.current.contains(event.target)) {
            handler()
        }
    }

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    })

    return refEle
}
