import * as React from 'react'
import {useThemeMode} from '../../../_metronic/partials'
import _clsx from 'clsx'
interface ToolBarProps {
    children: React.ReactNode
    title: string
}

export const ToolBar: React.FC<ToolBarProps> = ({children, title}) => {
    const {mode} = useThemeMode()

    return (
        <div className='app-toolbar'>
            <div className='app-container container-fluid d-flex justify-content-between align-items-center mb-2'>
                <h1
                    className={_clsx('', {
                        'text-white': mode === 'dark',
                    })}
                >
                    {title}
                </h1>
                <div className='d-flex align-items-center gap-2 gap-lg-3'>{children}</div>
            </div>
        </div>
    )
}
