import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import {WithChildren} from '../../../_metronic/helpers'
import {useAuthUser} from '../../contexts'
import {routes} from '../../routes'

export const UnAuthRouterProtection: React.FC<WithChildren> = ({children}) => {
    const auth = useAuthUser()
    const navigate = useNavigate()
    React.useEffect(() => {
        if (auth.isTryLogin && auth.currentUser) {
            navigate(routes.homeDashboard.href())
        }
    }, [auth.isTryLogin, auth.currentUser, navigate])

    return <>{children}</>
}
