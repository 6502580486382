/* eslint-disable @typescript-eslint/no-unused-vars */
import _clsx from 'clsx'
import { useFormikContext } from 'formik'
import * as React from 'react'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'
import { InputSize, InputWrapper } from './InputWrapper'
interface TextFieldProps extends CurrencyInputProps {
    name: string
    label: string
    spaceSize?: InputSize
}

export const NumberField: React.FC<TextFieldProps> = ({
    name,
    label,
    spaceSize = InputSize.MEDIUM,
    ...rest
}) => {
    const {getFieldProps, setFieldValue} = useFormikContext()

    return (
        <InputWrapper label={label} name={name} spaceSize={spaceSize}>
            <CurrencyInput
                // {...getFieldProps(name)}
                onValueChange={(value) => {
                    setFieldValue(name, value)
                }}
                className={_clsx('form-control bg-white')}
                name={name}
                {...rest}
            />
        </InputWrapper>
    )
}
