import * as React from 'react'
import { CookieComponent } from '../../_metronic/assets/ts/components'
import { WithChildren } from '../../_metronic/helpers'
import { userApi } from '../apis'
import { CONSTANTS } from '../constants'
import { User } from '../models'

interface IAuthUserContext {
    getCurrentUser: () => void
    handleLogout: () => void
    isTryLogin: boolean
    currentUser: User | undefined
}

const AuthUserContext = React.createContext<IAuthUserContext>({
    getCurrentUser: () => {},
    currentUser: undefined,
    handleLogout: () => {},
    isTryLogin: false,
})

export const AuthUserProvider: React.FC<WithChildren> = ({children}) => {
    const [currentUser, setCurrentUser] = React.useState<User>()
    const [isTryLogin, setIsTryLogin] = React.useState<boolean>(false)

    const getCurrentUser = async () => {
        try {
            const user = await userApi.v1About()

            setCurrentUser((pre) => ({
                ...user,
            }))
        } catch (error) {}
        setIsTryLogin(true)
    }
    const handleLogout = () => {
        setCurrentUser(undefined)
        CookieComponent.delete(CONSTANTS.ACCESS_TOKEN_KEY)
        CookieComponent.delete(CONSTANTS.REFRESH_TOKEN_KEY)
    }

    return (
        <AuthUserContext.Provider value={{getCurrentUser, currentUser, handleLogout, isTryLogin}}>
            {children}
        </AuthUserContext.Provider>
    )
}

export const useAuthUser = () => {
    const context = React.useContext(AuthUserContext)

    return {...context}
}
