import _get from 'lodash.get'
import { stringify } from 'query-string'
import { IPagingProps, User } from '../models'
import { http } from './http'

export interface IV1LoginDTO extends Pick<User, 'email' | 'password'> {}
export interface IV1UpdateMeDTO extends Pick<User, 'password' | 'phone' | 'gender' | 'full_name'> {}

export interface IV1CrmUserPagingFilters {
    role: string
    email: string
}
export interface IV1CrmUserPaging extends IPagingProps<IV1CrmUserPagingFilters> {}
export interface IV1CrmUserCreate
    extends Pick<User, 'email' | 'first_name' | 'last_name' | 'phone' | 'role'> {}

export const userApi = {
    v1Login: (input: IV1LoginDTO) => {
        return http.post('/v1/api/auth/login', input)
    },
    v1About: async (): Promise<User> => {
        const res = await http.get('/v1/api/user/about')
        return _get(res, 'data.data')
    },
    v1UpdateUser: async (input: IV1UpdateMeDTO) => {
        const res = await http.put(`/v1/api/user/update`, input)
        return _get(res, 'data.data')
    },
    v1CrmUserPaging: async (query: IV1CrmUserPaging) => {
        const res = await http.get(
            `/v1/api/user/crm/paging?${stringify({
                ...query,
                ...query.filters,
            })}`
        )
        return _get(res, 'data')
    },

    v1CreateCrmStaff: async (input: IV1CrmUserCreate) => {
        const res = await http.post(`/v1/api/user/crm`, input)
        return _get(res, 'data.data')
    },
    v1StaffById: async (id: string) => {
        const res = await http.get(`/v1/api/user/about/${id}`)
        return _get(res, 'data')
    },
}
