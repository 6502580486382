import moment from 'moment'

export const uppercaseFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const formatDateTime = (date: Date) => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss')
}

export const formMoneyVnd = (value: number) => {
    return value.toLocaleString('it-IT', {style: 'currency', currency: 'VND'})
}

export const translator = (key: string) => {
    const dictionary: Record<string, string> = {}

    return dictionary[key]
}
