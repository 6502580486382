import {IOptionItem} from './interface'

export enum StaticChartType {
    USER = 'RCRM_USER',
    JOB = 'RCRM_JOB',
    PROFILE = 'RCRM_PROFILE',
    PURCHASE_ORDER = 'RCRM_PURCHASE_ORDER',
    SALE_ORDER = 'RCRM_SALE_ORDER',
    TRANSACTION = 'RCRM_TRANSACTION',
}

export interface StaticChart {
    uuid: string
    value_by_day: {
        form_date: string
        to_date: string
        value: number
    }[]
    value_by_week: {
        form_date: string
        to_date: string
        value: number
    }[]
    value_by_year: {
        form_date: string
        to_date: string
        value: number
    }[]
    chart_other: {
        [key: string]: any
    }
    count_other: {
        [key: string]: number
    }
    count_by_day: number
    count_by_week: number
    count_by_month: number
    count_by_year: number
    type: StaticChartType
    date: string
}

export const optionsStaticChartType: IOptionItem[] = [
    {
        label: 'Job',
        value: StaticChartType.JOB,
        data: StaticChartType.JOB,
    },
    {
        label: 'User',
        value: StaticChartType.USER,
        data: StaticChartType.USER,
    },
    {
        label: 'Profile',
        value: StaticChartType.PROFILE,
        data: StaticChartType.PROFILE,
    },
    {
        label: 'Purchase Order',
        data: StaticChartType.PURCHASE_ORDER,
        value: StaticChartType.PURCHASE_ORDER,
    },
    {
        label: 'Sales Order',
        data: StaticChartType.SALE_ORDER,
        value: StaticChartType.SALE_ORDER,
    },
    {
        label: 'Transaction',
        value: StaticChartType.TRANSACTION,
        data: StaticChartType.TRANSACTION,
    },
]
