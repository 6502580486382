import _clsx from 'clsx'
import * as React from 'react'
import {useTableUtil} from '../../contexts'
import {SortOrder} from '../../models'

interface TableHeaderProps {
    label: string
    sortKey: string
}

export const TableHeader: React.FC<TableHeaderProps> = ({label, sortKey}) => {
    const {sortField, sortOrder, handleOnChangeOrderFiled} = useTableUtil()

    return (
        <th
            className={_clsx('cursor-pointer', {
                'table-sort-desc': sortField === sortKey && sortOrder === SortOrder.DESC,
                'table-sort-asc': sortField === sortKey && sortOrder === SortOrder.ASC,
            })}
            onClick={() => {
                handleOnChangeOrderFiled(sortKey)
            }}
        >
            {label}
        </th>
    )
}
