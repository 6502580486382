import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { reportApi } from '../../core/apis'
import { SelectField, TextField, TextareaField, ToolBar } from '../../core/components'
import { InputWrapper } from '../../core/components/form/InputWrapper'
import { configs } from '../../core/config'
import { useModal } from '../../core/contexts'
import {
    Report,
    ReportStatus,
    ReportType,
    reportStatusOptions,
    reportTypeOptions,
} from '../../core/models'
import { routes } from '../../core/routes'
import { TicketChangeTypeModal } from './components/TicketChangeTypeModal'
import { TicketUpdateModal } from './components/TicketUpdateModal'

const defaultValue: Report = {
    uuid: '',
    title: '',
    description: '',
    status: ReportStatus.OPEN,
    type_report: ReportType.R_TECH,
    createdAt: '',
    updatedAt: '',
    files: '',
    user: undefined,
}

interface TicketAdminByIdProps {}

export const TicketAdminById: React.FC<TicketAdminByIdProps> = () => {
    const params = useParams<{ticketId: string}>()
    const [imageUrl, setImageUrl] = React.useState<string>('')
    const updateModalMethod = useModal('edit')
    const updateTypeModalMethod = useModal('changeType')
    const formik = useFormik<Report>({
        initialValues: defaultValue,
        onSubmit: (values) => {},
    })
    const query = useQuery<Report>(
        [
            'ticketById',
            params.ticketId,
            updateModalMethod.getIsOpen(),
            updateTypeModalMethod.getIsOpen(),
        ],
        async () => {
            const {data} = await reportApi.v1ById(params.ticketId || '')

            return data.data
        },
        {
            enabled: Boolean(params.ticketId),
            initialData: defaultValue,
            onSuccess: (data) => {
                setImageUrl(data.files)
                formik.setFieldValue('senderEmail', data.user?.email)
                formik.setFieldValue('createdAt', data.createdAt)
                formik.setFieldValue('type_report', data.type_report)
                formik.setFieldValue('status', data.status)
                formik.setFieldValue('description', data.description)
            },
        }
    )

    return (
        <>
            {updateModalMethod.getIsOpen() && <TicketUpdateModal />}
            {updateTypeModalMethod.getIsOpen() && <TicketChangeTypeModal />}
            <ToolBar title={'Ticket Detail'}>
                <Link
                    to={routes.ticketAdminManagement.href()}
                    className='btn btn-sm fw-bold btn-primary'
                >
                    Go Back
                </Link>
            </ToolBar>
            <div className='mt-4 app-container fade-in'>
                <div className='row'>
                    <div className='col-xl-6  col-12 bg-white p-4 '>
                        <FormikProvider value={formik}>
                            <h1 className='mb-5'>{query.data?.title}</h1>
                            <form onSubmit={formik.handleSubmit}>
                                <TextField name='senderEmail' label='Sender Email' disabled />
                                <TextField name='createdAt' label='Created At' disabled />
                                <TextareaField name='description' label='Description' disabled />

                                <SelectField
                                    label='Status'
                                    name='status'
                                    options={reportStatusOptions}
                                    disabled
                                />
                                <SelectField
                                    label='Type'
                                    name='type_report'
                                    options={reportTypeOptions}
                                    disabled
                                />
                                <InputWrapper label='Image' name='image'>
                                    <img
                                        src={`${configs.REACT_APP_IMAGE_BASE_URL}${imageUrl}`}
                                        className='img-thumbnail'
                                        alt={query.data?.title}
                                    />
                                </InputWrapper>
                            </form>
                        </FormikProvider>
                    </div>
                </div>
            </div>
        </>
    )
}
