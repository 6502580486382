import * as React from 'react'

interface DropDownMenuProps {
    isShow: Boolean

    children: React.ReactNode
}

export const DropDownMenu: React.FC<DropDownMenuProps> = ({isShow, children}) => {
    const tagRef = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        if (isShow && tagRef.current) {
            tagRef.current?.classList.add('show')
        }
        if (!isShow && tagRef.current) {
            tagRef.current?.classList.remove('show')
        }
    }, [isShow])

    return (
        <div
            className='menu menu-sub menu-sub-dropdown position-absolute'
            data-kt-menu='true'
            data-popper-placement='bottom-end'
            ref={tagRef}
        >
            <div className='px-2 py-2'>{children}</div>
        </div>
    )
}
