/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useAuthUser} from '../../../../../core/contexts'
import {UserRole} from '../../../../../core/models'
import {routes} from '../../../../../core/routes'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
    const user = useAuthUser()

    return (
        <>
            {user.currentUser?.role === UserRole.STAFF_ADMIN && (
                <>
                    <SidebarMenuItemWithSub
                        to='#'
                        title='Staff'
                        icon='/media/icons/duotune/communication/com006.svg'
                    >
                        <SidebarMenuItem
                            to={routes.staffManagement.href()}
                            title='List'
                            hasBullet={true}
                        />
                    </SidebarMenuItemWithSub>
                    <SidebarMenuItemWithSub to='#' title='Ticket' fontIcon='bi-ticket'>
                        <SidebarMenuItem
                            to={routes.ticketAdminManagement.href()}
                            title='List'
                            hasBullet={true}
                        />
                    </SidebarMenuItemWithSub>
                    <SidebarMenuItemWithSub to='#' title='Purchase Order' fontIcon='bi-receipt'>
                        <SidebarMenuItem
                            to={routes.purchaseOrderAdminManagement.href()}
                            title='List'
                            hasBullet={true}
                        />
                    </SidebarMenuItemWithSub>
                    <SidebarMenuItemWithSub to='#' title='Sale Order' fontIcon='bi-cart'>
                        <SidebarMenuItem
                            to={routes.saleOrderManagement.href()}
                            title='List'
                            hasBullet={true}
                        />
                    </SidebarMenuItemWithSub>
                    <SidebarMenuItemWithSub to='#' title='Claim Email' fontIcon='bi-envelope'>
                        <SidebarMenuItem
                            to={routes.claimEmailManagement.href()}
                            title='List'
                            hasBullet={true}
                        />
                    </SidebarMenuItemWithSub>
                </>
            )}

            {user.currentUser?.role !== UserRole.STAFF_ADMIN && (
                <>
                    {user.currentUser?.role === UserRole.STAFF_TECH && (
                        <>
                            <SidebarMenuItemWithSub to='#' title='Ticket' fontIcon='bi-ticket'>
                                <SidebarMenuItem
                                    to={routes.ticketManagement.href()}
                                    title='List'
                                    hasBullet={true}
                                />
                            </SidebarMenuItemWithSub>
                        </>
                    )}
                    {user.currentUser?.role === UserRole.STAFF_RECEIPT && (
                        <>
                            <SidebarMenuItemWithSub to='#' title='Ticket' fontIcon='bi-ticket'>
                                <SidebarMenuItem
                                    to={routes.ticketManagement.href()}
                                    title='List'
                                    hasBullet={true}
                                />
                            </SidebarMenuItemWithSub>
                            <SidebarMenuItemWithSub
                                to='#'
                                title='Purchase Order'
                                fontIcon='bi-receipt'
                            >
                                <SidebarMenuItem
                                    to={routes.purchaseOrderManagement.href()}
                                    title='List'
                                    hasBullet={true}
                                />
                            </SidebarMenuItemWithSub>
                            <SidebarMenuItemWithSub to='#' title='Sale Order' fontIcon='bi-cart'>
                                <SidebarMenuItem
                                    to={routes.saleOrderManagement.href()}
                                    title='List'
                                    hasBullet={true}
                                />
                            </SidebarMenuItemWithSub>
                        </>
                    )}

                    {/* <SidebarMenuItemWithSub to='#' title='Sales Order Management' fontIcon='bi-shop'>
                <SidebarMenuItem
                    to={routes.saleOrderManagement.href()}
                    title='List'
                    hasBullet={true}
                />
            </SidebarMenuItemWithSub>
            <SidebarMenuItemWithSub to='#' title='Report' fontIcon='bi-bar-chart'>
                <SidebarMenuItem
                    to={routes.ticketManagement.href()}
                    title='List'
                    hasBullet={true}
                />
            </SidebarMenuItemWithSub> */}
                    <SidebarMenuItemWithSub
                        to='#'
                        title='My Account'
                        icon='/media/icons/duotune/communication/com006.svg'
                    >
                        <SidebarMenuItem
                            to={routes.accountProfile.href()}
                            title='Profile'
                            hasBullet={true}
                        />
                    </SidebarMenuItemWithSub>
                    <SidebarMenuItemWithSub to='#' title='Chat' fontIcon='bi-chat'>
                        <SidebarMenuItem
                            to={routes.chatHome.href()}
                            title='Chat'
                            hasBullet={true}
                        />
                    </SidebarMenuItemWithSub>
                </>
            )}

            <SidebarMenuItemWithSub to='#' title='Log' fontIcon='bi-gear'>
                <SidebarMenuItem
                    to={routes.logActivityLogManagement.href()}
                    title='Activity Log'
                    hasBullet={true}
                />
            </SidebarMenuItemWithSub>
        </>
    )
}

export {SidebarMenuMain}
