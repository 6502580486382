import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAuthUser } from '../../core/contexts'
import { UserStatus } from '../../core/models'
import { routes } from '../../core/routes'
import { reInitMenu } from '../helpers'
import {
    ActivityDrawer,
    DrawerMessenger,
    InviteUsers,
    ThemeModeProvider,
    UpgradePlan,
} from '../partials'
import { RightToolbar } from '../partials/layout/RightToolbar'
import { Content } from './components/content'
import { HeaderWrapper } from './components/header'
import { ScrollTop } from './components/scroll-top'
import { Sidebar } from './components/sidebar'
import { PageDataProvider } from './core'

const MasterLayout = () => {
    const location = useLocation()
    const currentUser = useAuthUser()
    const navigator = useNavigate()
    useEffect(() => {
        reInitMenu()
    }, [location.key])

    useEffect(() => {
        if (
            currentUser.currentUser?.uuid &&
            currentUser.currentUser?.status === UserStatus.REQUEST_CHANGE_PASSWORD &&
            location.pathname !== routes.accountProfile.href()
        ) {
            navigator(routes.accountProfile.href())
            toast.warn('Please change your password before doing any action')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser])

    return (
        <PageDataProvider>
            <ThemeModeProvider>
                <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
                    <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
                        <HeaderWrapper />
                        <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
                            <Sidebar />
                            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                                <div className='d-flex flex-column flex-column-fluid'>
                                    {/* <ToolbarWrapper /> */}
                                    <Content>
                                        <Outlet />
                                    </Content>
                                </div>
                                {/* <FooterWrapper /> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* begin:: Drawers */}
                <ActivityDrawer />
                <RightToolbar />
                <DrawerMessenger />
                {/* end:: Drawers */}

                {/* begin:: Modals */}
                <InviteUsers />
                <UpgradePlan />
                {/* end:: Modals */}
                <ScrollTop />
            </ThemeModeProvider>
        </PageDataProvider>
    )
}

export { MasterLayout }
