import {Activity, IResponseList} from '../models'
import {stringify} from 'query-string'
import {IPagingProps} from '../models'
import {http} from './http'

export interface IV1ActivityFilters {}
export interface IV1ActivityPaging extends IPagingProps<IV1ActivityFilters> {}
export interface IV1ActivityAdminFilters {}
export interface IV1ActivityAdminPaging extends IPagingProps<IV1ActivityAdminFilters> {}

export const activityApi = {
    v1Paging: async (query: IV1ActivityPaging): Promise<IResponseList<Activity>> => {
        const res = await http.get(
            `/v1/api/history-activity/paging?${stringify({
                ...query,
                ...query.filters,
            })}`
        )
        return res.data.data
    },
    v1GetById: async (id: string) => {
        const res = await http.get(`/v1/api/history-activity/${id}`)
        return res.data
    },
    v1AdminPaging: async (userId: string, query: IV1ActivityAdminPaging) => {
        const res = await http.get(
            `/v1/api/history-activity/paging/${userId}?${stringify({
                ...query,
                ...query.filters,
            })}`
        )

        return res.data
    },
    v1AdminGetById: async (id: string) => {
        const res = await http.get(`/v1/api/history-activity/${id}`)
        return res.data
    },
}
