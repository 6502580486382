import * as React from 'react'

interface StatisticalCardProps {
    label: string
    value: number
    backgroundColor: string
}

export const StatisticalCard: React.FC<StatisticalCardProps> = ({
    label,
    value,
    backgroundColor,
}) => {
    return (
        <div
            className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-5 mb-xl-10 '
            style={{background: backgroundColor}}
        >
            <div className='card-header pt-5'>
                <div className='card-title d-flex flex-column'>
                    <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{value}</span>
                    <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>{label}</span>
                </div>
            </div>
            <div className='card-body d-flex align-items-end pt-0'>
                <div className='d-flex align-items-center flex-column mt-3 w-100'>
                    <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
                        {/* <span>43 Pending</span>
                        <span>72%</span> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
