import { stringify } from 'query-string'
import { IPagingProps, Report } from '../models'
import { http } from './http'

export interface IV1ReportMeFilters extends Pick<Partial<Report>, 'title'> {
    status: string
}
export interface IV1ReportMe extends IPagingProps<IV1ReportMeFilters> {}
export interface IV1ReportPagingFilters extends Pick<Partial<Report>, 'title'> {
    status: string
}

export interface IV1ReportPaging extends IPagingProps<IV1ReportPagingFilters> {}

export interface IV1ReportUpdate extends Pick<Report, 'status'> {}
export interface IV1ReportChangeType {
    type: string
}

export const reportApi = {
    v1Me: (query: IV1ReportMe) => {
        return http.get(
            `/v1/api/report/me?${stringify({
                ...query,
                ...query.filters,
            })}`
        )
    },
    v1ById: (id: string) => {
        return http.get(`/v1/api/report/${id}`)
    },
    v1Update: (id: string, input: IV1ReportUpdate) => {
        return http.put(`/v1/api/report/${id}`, input)
    },
    v1UpdateType: (id: string, input: IV1ReportChangeType) => {
        return http.put(`/v1/api/report/other-type/${id}`, input)
    },
    v1AdminReport: (query: IV1ReportPaging) => {
        return http.get(
            `/v1/api/report/paging?${stringify({
                ...query,
                ...query.filters,
            })}`
        )
    },
}
