import {User} from './user'

export enum SaleOrderType {
    UPLOAD_NEWS = 'UPLOAD_NEWS',
    SUGGEST = 'SUGGEST',
    BUY_CV = 'BUY_CV',
    JOB_POINT = 'JOB_POINT',
    TRADE_CV = 'TRADE_CV',
    POINT_EXPIRED = 'POINT_EXPIRED',
    BUY_APPLICATION = 'BUY_APPLICATION',
    POINT_REQUEST_APPROVED = 'POINT_REQUEST_APPROVED',
    JOB_TAG = 'JOB_TAG',
    JOB_EXTENSION = 'JOB_EXTENSION',
    JOB_GUARANTEE = 'JOB_GUARANTEE',
}

export interface SaleOrder {
    createdAt: string
    updatedAt: string
    uuid: string
    so_crm_id: string
    type: SaleOrderType
    is_sent_to_crm: number
    point_consumption: number
    point_before: number
    point_current: number
    client_ip: string
    user: User | undefined
}

export const saleOrderOptions = [
    {
        value: SaleOrderType.UPLOAD_NEWS,
        label: 'Post news',
        data: SaleOrderType.UPLOAD_NEWS,
    },
    {
        value: SaleOrderType.SUGGEST,
        label: 'Suggest',
        data: SaleOrderType.SUGGEST,
    },
    {
        value: SaleOrderType.BUY_CV,
        label: 'Buy CV',
        data: SaleOrderType.BUY_CV,
    },
    {
        value: SaleOrderType.JOB_POINT,
        label: 'Post job',
        data: SaleOrderType.JOB_POINT,
    },
    {
        value: SaleOrderType.TRADE_CV,
        label: 'Trading CV',
        data: SaleOrderType.TRADE_CV,
    },
    {
        value: SaleOrderType.JOB_TAG,
        label: 'Tag',
        data: SaleOrderType.JOB_TAG,
    },
    {
        value: SaleOrderType.BUY_APPLICATION,
        label: 'Buy Application',
        data: SaleOrderType.BUY_APPLICATION,
    },
    {
        value: SaleOrderType.JOB_EXTENSION,
        label: 'Job Extend',
        data: SaleOrderType.JOB_EXTENSION,
    },
    {
        value: SaleOrderType.JOB_GUARANTEE,
        label: 'Post job guarantee',
        data: SaleOrderType.JOB_GUARANTEE,
    },
]
