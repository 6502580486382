import * as React from 'react'

interface FormButtonProps {
    label: string
    isLoading: boolean
}

export const FormButton: React.FC<FormButtonProps> = ({isLoading, label}) => {
    const btnRef = React.useRef<HTMLButtonElement | null>(null)

    React.useEffect(() => {
        if (isLoading && btnRef.current) {
            btnRef.current?.setAttribute('data-kt-indicator', 'on')
        }
        if (!isLoading && btnRef.current) {
            btnRef.current?.removeAttribute('data-kt-indicator')
        }
    }, [isLoading])

    return (
        <button className='btn btn-primary me-10' ref={btnRef}>
            <span className='indicator-label'>{label}</span>
            <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
        </button>
    )
}
