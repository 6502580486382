/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'

import { IOptionItem } from '../models'

export enum SELECT_OPTIONS {}

export const useSelectOptions = (input: SELECT_OPTIONS) => {
    const [options, setOptions] = React.useState<IOptionItem[]>([])

    const _handleOnChangeOptions = React.useCallback((input: SELECT_OPTIONS) => {}, [])

    React.useEffect(() => {
        _handleOnChangeOptions(input)
    }, [_handleOnChangeOptions, input])

    return options
}
