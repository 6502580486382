import { useFormikContext } from 'formik'
import * as React from 'react'

interface FormAutoSubmitProps {
    delay?: number
}

export const FormAutoSubmit: React.FC<FormAutoSubmitProps> = ({delay = 300}) => {
    const formMethods = useFormikContext()
    React.useEffect(() => {
        const timer = setTimeout(() => {
            formMethods.submitForm()
        }, delay)
        return () => {
            clearTimeout(timer)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formMethods.values])

    return <></>
}
