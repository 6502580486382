import * as React from 'react'
import _get from 'lodash.get'
import _clsx from 'clsx'
import {useStoreApi} from '../../stores'

export enum InputSize {
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
}

interface InputWrapperProps {
    label: string
    children: React.ReactNode
    name: string
    spaceSize?: InputSize
}

export const InputWrapper: React.FC<InputWrapperProps> = ({
    children,
    label,
    name,
    spaceSize = InputSize.SMALL,
}) => {
    const [errorMessage, setErrorMessage] = React.useState('')
    const apiStore = useStoreApi()

    React.useEffect(() => {
        const error = _get(apiStore.errorDetails, `${name}`, '')

        if (!error) {
            setErrorMessage('')
        } else {
            setErrorMessage(error)
        }
    }, [apiStore, name])

    return (
        <div
            className={_clsx('fv-row', {
                'mb-8': spaceSize === InputSize.MEDIUM,
                'mb-2': spaceSize === InputSize.SMALL,
                'mb-10': spaceSize === InputSize.LARGE,
            })}
        >
            <label className='form-label fs-6 fw-bolder text-dark'>{label}</label>
            {children}
            {Boolean(errorMessage) && (
                <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{errorMessage}</span>
                </div>
            )}
        </div>
    )
}
