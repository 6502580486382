// export const UserUpdateModalHeader: React.FC = () => {
//     const modalMethod = useModal()

//     return (
//         <div className='modal-header'>
//             {/* begin::Modal title */}
//             <h2 className='fw-bolder'>Update User</h2>
//             {/* end::Modal title */}

//             {/* begin::Close */}
//             <div
//                 className='btn btn-icon btn-sm btn-active-icon-primary'
//                 data-kt-users-modal-action='close'
//                 style={{cursor: 'pointer'}}
//                 onClick={() => modalMethod.handleSetModalValue(undefined)}
//             >
//                 <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
//             </div>
//             {/* end::Close */}
//         </div>
//     )
// }

import * as React from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { useModal } from '../../contexts'
import { useThemeMode } from '../../../_metronic/partials'
import _clsx from 'clsx'

interface ModalHeaderProps {
    title: string
    modalKey: string
    disabled?: boolean
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ title, modalKey, disabled = false }) => {
    const modalMethod = useModal(modalKey)
    const {mode} = useThemeMode()
    return (
        <div className='modal-header'>
            {/* begin::Modal title */}
            <h2 className={_clsx('fw-bolder', {
                'text-white': !disabled,
                'text-dark': disabled && mode === 'dark',
                'text-light': disabled && mode === 'light',
            })}>{title}</h2>
            {/* end::Modal title */}

            {/* begin::Close */}
            <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                style={{ cursor: 'pointer' }}
                onClick={() => modalMethod.handleCloseModal()}
            >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
            {/* end::Close */}
        </div>
    )
}
